import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { RegistrationService } from '../shared/registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  host: {
    class: 'registration'
  },
})
export class RegistrationComponent implements OnInit, OnDestroy {

  private readonly subs: SubSink;

  constructor(private readonly registrationService: RegistrationService) {
    this.subs = new SubSink();
  }

  ngOnInit() {
    this.subs.add(this.registrationService.loadDriver().subscribe());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
