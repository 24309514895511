import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Employment, Unemployment } from 'src/app/shared/models/employment.model';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { Location } from '@angular/common';
import dayjs, { Dayjs } from 'dayjs';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ActivatedRoute } from '@angular/router';
import { Driver } from 'src/app/shared/models/driver.model';
import { of } from 'rxjs';
import { StorageService } from 'src/app/shared/storage/storage.service';

const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const EMPLOYMENT_STATE_UNEMPLOYED = 'unemployed';
const YEAR_MONTH_FORMAT = 'YYYY-MM';
const REPEATED_RECORDS_ERROR_MESSAGE =
  'You have already added an unemployment record with same period. Please modify the date to save it';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@Component({
  selector: 'app-employment-history-form-unemployed',
  templateUrl: './employment-history-form-unemployed.component.html',
  styleUrls: [
    './employment-history-form-unemployed.component.scss',
    '../employment-history-form-employed/employment-history-form-employed.component.scss'
  ],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class EmploymentHistoryFormUnemployedComponent implements OnInit {
  employmentList = [];
  isFirstRecord: boolean;
  unEmpForm: FormGroup;
  errorMessage: string;
  isUpdating: boolean;
  lastDateOfMonth = dayjs()
    .endOf('month')
    .valueOf();
  currentDate = new Date(this.lastDateOfMonth);
  lastDate = dayjs(this.currentDate).subtract(10, 'year');
  isGreaterThan10years: boolean = false;
  editFormTitle: string = 'Unemployment';
  minFromDate;
  maxToDate = dayjs(this.currentDate);
  isContentReady: boolean = false;
  isModifyNewCurrentAddress: boolean;
  driver: Driver;
  currentNewUnEmployment;

  @ViewChildren('unEmpFromDatePicker') unEmpFromDateList;
  @ViewChildren('unEmpToDatePicker') unEmpToDateList;
  private subs = new SubSink();

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly registrationService: RegistrationService,
    private readonly location: Location,
    private readonly driverApiService: DriverApiService,
    private readonly timeService: DateTimeService,
    private readonly fb: FormBuilder,
    private readonly toastService: ToastService,
    private readonly storageService: StorageService,
    @Inject('environmentData') public environment: any
  ) {
    this.errorMessage = undefined;
    this.unEmpForm = this.createUnEmploymentForm();
  }

  ngOnInit(): void {
    this.driver = this.registrationService.getDriver();
    this.currentNewUnEmployment = this.storageService.find('currentEmploymentState');
    this.loadExperience();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onAddUnemployment(isMissingIndex?: number): void {
    this.unEmpForm = this.createUnEmploymentForm();
    this.editFormTitle = 'Unemployment';
    if (this.isFirstRecord || this.isModifyNewCurrentAddress) {
      this.editFormTitle = `Current ${this.editFormTitle}`;
      this.unEmpToDate.setValue(this.currentDate);
    } else {
      this.editFormTitle = `Previous ${this.editFormTitle}`;
      const toDate = dayjs(this.employmentList[this.employmentList.length - 1].fromDate);
      this.unEmpForm.get('toDate').setValue(toDate);
      this.unEmpForm.get('isCurrentEmployment').setValue(false);
    }
    this.maxToDate = dayjs(this.currentDate);
    if (isMissingIndex) {
      this.updateDateMaxAndMin(this.unEmpForm, isMissingIndex);
    }
    if (this.isModifyNewCurrentAddress) {
      // When user adds new current record, they should not be able to choose toDate month of previous record (i.e. first record)
      this.minFromDate = dayjs(
        this.currentNewUnEmployment?.isCurrentEmployment
          ? this.employmentList[0].fromDate
          : this.employmentList[0].toDate
      )
        .add(1, 'month')
        .set('date', 1);
    }
  }

  //here element will be used to detect the datepicker control
  chosenMonthHandler(
    normalizedMonth: Dayjs,
    datepicker: MatDatepicker<Dayjs>,
    currentForm: FormGroup,
    element?: string
  ): void {
    const date = dayjs()
      .set('month', normalizedMonth.get('month'))
      .set('year', normalizedMonth.get('year'));

    if (element) {
      const assignDate = dayjs(date).endOf('month');
      currentForm.patchValue({
        toDate: assignDate
      });
    } else {
      const assignDate = dayjs(date).set('date', 1);
      let currentToDate = dayjs(currentForm.get('toDate').value);

      if (
        currentToDate.get('month') === assignDate.get('month') &&
        currentToDate.get('year') === assignDate.get('year')
      ) {
        currentToDate = dayjs(currentToDate).endOf('month');
      } else {
        currentToDate = dayjs(currentToDate).set('date', 1);
      }

      currentForm.patchValue({
        fromDate: assignDate
      });
      currentForm.get('toDate').setValue(currentToDate, { emitEvent: false });
    }

    this.unEmpForm.updateValueAndValidity();
    datepicker.close();

    // To not allow repetitive records of unemp - check if prev record is unemployment type and fromDate and toDate month/year are same as current chosen toDate and fromDate
    let prevEmp = this.employmentList[this.employmentList.length - 1];
    if (
      prevEmp &&
      prevEmp.employmentState === EMPLOYMENT_STATE_UNEMPLOYED &&
      [
        dayjs(prevEmp.fromDate).format(YEAR_MONTH_FORMAT),
        dayjs(prevEmp.toDate).format(YEAR_MONTH_FORMAT),
        dayjs(this.unEmpFromDate.value).format(YEAR_MONTH_FORMAT),
        dayjs(this.unEmpToDate.value).format(YEAR_MONTH_FORMAT)
      ].every((val, i, arr) => val === arr[0])
    ) {
      this.errorMessage = REPEATED_RECORDS_ERROR_MESSAGE;
    } else {
      this.errorMessage = undefined;
    }
  }

  onUpdateEmploymentPressed($event): void {
    if ($event) {
      this.maxToDate = dayjs(this.currentDate);
      this.unEmpForm = this.createUnEmploymentForm();
      this.editFormTitle = 'Unemployment';

      this.populateUnemployedForm($event);

      if ($event?.isCurrentEmployment) {
        this.editFormTitle = `Current ${this.editFormTitle}`;
      } else {
        this.editFormTitle = `Previous ${this.editFormTitle}`;
      }
    }
  }

  onCancel(): void {
    this.location.back();
  }

  onLog() {
    console.log(this.unEmpForm, 'Form Object');
  }

  goBack(): void {
    this.location.back();
  }

  onSubmit(form: FormGroup): void {
    this.isContentReady = false;
    if (!form.valid) {
      return;
    }
    let model = null;

    model = this.prepareUnEmploymentModel();
    const modelWithId = { ...model };

    if (model && model.id) {
      let id = model.id;
      delete model.id;
      if (model.isCurrentEmployment) {
        delete model.toDate;
      }
      this.subs.add(
        this.registrationService.updateEmployment(model, id).subscribe(
          () => {
            const updatedEmp = this.employmentList.findIndex(emp => emp.id === modelWithId.id);
            this.employmentList[updatedEmp] = modelWithId;
            this.registrationService.updateEmploymentHistoryStore([...this.employmentList]);
            this.onCancel();
          },
          (error: any) => {
            this.toastService.showError(error?.message);
            this.isContentReady = true;
          }
        )
      );
    } else if (model) {
      if (this.currentNewUnEmployment?.isCurrentEmployment) {
        this.currentNewUnEmployment.isCurrentEmployment = false;
        this.currentNewUnEmployment.toDate = model.fromDate;
        const id = this.currentNewUnEmployment.id;
        delete this.currentNewUnEmployment.id;
        delete this.currentNewUnEmployment.modifiedDate;
        delete this.currentNewUnEmployment.creationDate;
        delete this.currentNewUnEmployment.version;
        delete this.currentNewUnEmployment.reasonForLeaving;
        this.updateExistingAndCreateNewEmployment(this.currentNewUnEmployment, id, model);
      } else {
        this.onEmploymentAdded(model);
      }
    } else {
      this.onCancel();
    }
    this.unEmpForm.reset();
  }

  private updateExistingAndCreateNewEmployment(
    model: Unemployment,
    id: string,
    newCurrentEmployment?: Unemployment
  ): void {
    this.isContentReady = false;
    this.registrationService
      .updateEmployment(model, id)
      .pipe(
        catchError((error: any) => {
          this.isContentReady = true;
          this.toastService.showError(error?.message);
          return of(false);
        })
      )
      .subscribe(res => {
        if (res) {
          this.errorMessage = undefined;
          if (this.currentNewUnEmployment) {
            const modelToSave = { ...model, id: id };
            const updatedEmp = this.employmentList.findIndex(emp => emp.id === id);
            this.employmentList[updatedEmp] = modelToSave;
            this.registrationService.updateEmploymentHistoryStore([...this.employmentList]);
            this.storageService.store('currentEmploymentState', null);
            this.onEmploymentAdded(newCurrentEmployment);
          }
        }
      });
  }

  get unEmpFromDate() {
    return this.unEmpForm.get('fromDate');
  }
  get unEmpToDate() {
    return this.unEmpForm.get('toDate');
  }
  get isCurrentUnemployment() {
    return this.unEmpForm.get('isCurrentEmployment');
  }

  getunEmpFromDateErrorMessage() {
    return this.unEmpFromDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getunEmpToDateErrorMessage() {
    return this.unEmpToDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  onDatePickerClick(datepicker: any) {
    datepicker.open();
  }

  private updateDateMaxAndMin(form: FormGroup, index: number): void {
    form.get('toDate').patchValue(this.employmentList[index - 1].fromDate);
    form.get('fromDate').patchValue(this.employmentList[index + 1].toDate);
    this.minFromDate = dayjs(this.employmentList[index + 1].toDate);
    this.maxToDate = dayjs(this.employmentList[index - 1].fromDate);
  }

  private createUnEmploymentForm(): FormGroup {
    const form = this.fb.group({
      fromDate: ['', [Validators.required, this.checkFromDateValidation.bind(this)]],
      toDate: ['', [Validators.required, this.checkToDateValidation.bind(this)]],
      reason: [''],
      id: [''],
      isCurrentEmployment: ['']
    });
    return form;
  }

  private checkFromDateValidation(control: AbstractControl): Promise<any> {
    if (control && control?.parent) {
      let fromDate = control?.value;
      const toDate = control?.parent?.get('toDate').value || this.currentDate;
      if (fromDate && toDate && dayjs(fromDate) >= dayjs(toDate)) {
        return Promise.resolve({
          maxDateError: true
        });
      }
    }
    return null;
  }

  private checkToDateValidation(control: AbstractControl): Promise<any> {
    if (control && control?.parent) {
      let toDate = control?.value;
      const fromDate = control?.parent?.get('fromDate').value;
      if (fromDate && toDate && dayjs(toDate) <= dayjs(fromDate)) {
        return Promise.resolve({
          minDateError: true
        });
      }
    }
    return null;
  }

  private prepareUnEmploymentModel(): Unemployment {
    const formModel = this.unEmpForm.value;
    const model = {
      id: formModel.id as string,
      fromDate: dayjs(formModel.fromDate).format('YYYY-MM-DD') as string,
      toDate: dayjs(formModel.toDate).format('YYYY-MM-DD') as string,
      reasonForLeaving: formModel.reason as string,
      employmentState: EMPLOYMENT_STATE_UNEMPLOYED,
      isCurrentEmployment: formModel?.isCurrentEmployment ? formModel.isCurrentEmployment : ''
    } as Unemployment;
    if (this.isFirstRecord || formModel?.isCurrentEmployment) {
      model.isCurrentEmployment = true;
      delete model.toDate;
    }
    if (!formModel.reason) {
      delete model.reasonForLeaving;
    }
    Object.keys(model).forEach(key => {
      if (model[key] === null || model[key] === '') {
        delete model[key];
      }
    });

    return model;
  }

  private populateUnemployedForm(unemployed: Unemployment): void {
    this.isUpdating = true;
    this.unEmpForm.patchValue({
      id: unemployed.id,
      fromDate: unemployed.fromDate,
      toDate: unemployed.toDate || (dayjs(this.currentDate).format('YYYY-MM-DD') as string),
      reason: unemployed.reasonForLeaving ? unemployed.reasonForLeaving : '',
      isCurrentEmployment: unemployed?.isCurrentEmployment
    });
  }

  private onEmploymentAdded(model: Unemployment) {
    // TODO: Remove this logic when API ignores these fields.
    model.id = undefined;
    if (this.isModifyNewCurrentAddress) {
      model.isCurrentEmployment = true;
      delete model.toDate;
    }
    this.isContentReady = false;
    this.subs.add(
      this.registrationService
        .saveEmployment(model)
        .pipe(
          catchError((error: any) => {
            this.isContentReady = true;
            this.toastService.showError(error?.message);
            return of(false);
          })
        )
        .subscribe(response => {
          if (response?.data) {
            const empRecords = [...this.employmentList];
            empRecords.push(response?.data);
            const recordsToSave = [...empRecords];
            this.registrationService.updateEmploymentHistoryStore(recordsToSave);
          }
          this.onCancel();
        })
    );
  }

  private loadExperience(): void {
    this.isContentReady = false;
    this.registrationService.getEmploymentHistory().subscribe(response => {
      this.employmentList = [...response];
      if (this.employmentList) {
        if (this.employmentList?.length) {
          this.employmentList.sort((a, b) => {
            const fromDate1 = new Date(b.fromDate).getTime();
            const fromDate2 = new Date(a.fromDate).getTime();

            if (fromDate1 !== fromDate2) {
              return fromDate1 - fromDate2;
            } else {
              const toDate1 = b.toDate ? b.toDate : this.currentDate;
              const toDate2 = a.toDate ? a.toDate : this.currentDate;
              return new Date(toDate1).getTime() - new Date(toDate2).getTime();
            }
          });

          this.isFirstRecord = false;
          this.checkMissingGap();
        } else {
          this.isGreaterThan10years = false;
          this.isFirstRecord = true;
        }
        this.isContentReady = true;
        this.activatedRoute.queryParams.subscribe(params => {
          let missingIndex = params.missingIndex ? params.missingIndex : 0;
          if (params.newCurrentAddress) {
            this.isModifyNewCurrentAddress = true;
          }
          this.onAddUnemployment(parseInt(missingIndex));
        });
        this.activatedRoute.params.subscribe(params => {
          if (params.id) {
            let empEvent = this.employmentList.find(el => el.id === params.id);
            if (empEvent) {
              this.onUpdateEmploymentPressed(empEvent);
            }
          }
        });
      } else {
        this.location.back();
      }
    });
  }

  private checkMissingGap(): void {
    let isYearsInOrder = true;
    let employmentData: any[] = [...this.employmentList];
    const currentEmployment = this.employmentList.filter(e => e.isCurrentEmployment);
    let currentDifferenceMonth = 0;
    const currentEmpToDate = dayjs(this.currentDate);
    const currentEmpFromDate = dayjs(currentEmployment[0]?.fromDate);
    currentDifferenceMonth = Math.round(currentEmpToDate.diff(currentEmpFromDate, 'month', true));

    if (this.employmentList.length > 1) {
      let spliceIndex = 0;
      for (let index = 0; index < employmentData.length - 1; index++) {
        if (employmentData[index + 1]) {
          const date2CompareToDate = dayjs(employmentData[index + 1].toDate);
          const date2CompareFromDate = dayjs(employmentData[index].fromDate);
          if (date2CompareToDate.diff(date2CompareFromDate, 'month') === 0) {
            const currToDate = dayjs(employmentData[index + 1].toDate);
            const currFromDate = dayjs(employmentData[index + 1].fromDate);

            currentDifferenceMonth += Math.round(currToDate.diff(currFromDate, 'month', true));

            if (this.checkEmploymentHasTenYearsInfo(currentDifferenceMonth, isYearsInOrder)) {
              return;
            }
          } else {
            if (!spliceIndex) {
              spliceIndex = index + 1;
            } else {
              spliceIndex += index;
            }
            if (employmentData.length - 1 === index + 1 && employmentData.length - 2 === index) {
              this.employmentList.splice(this.employmentList.length - 1, 0, { fromDate: null } as Employment);
            } else {
              this.employmentList.splice(spliceIndex, 0, { fromDate: null } as Employment);
            }
            if (index === 0) {
              isYearsInOrder = this.checkIsYearsInOrder(currentDifferenceMonth);
            } else {
              isYearsInOrder = this.checkIsYearsInOrder(currentDifferenceMonth);
            }
          }
        }
      }
    } else {
      this.checkEmploymentHasTenYearsInfo(currentDifferenceMonth, isYearsInOrder);
    }
  }

  private checkEmploymentHasTenYearsInfo(month: number, isYearsInOrder: boolean): boolean {
    if (month >= 120 && isYearsInOrder) {
      this.isGreaterThan10years = true;
      return true;
    } else {
      this.isGreaterThan10years = false;
      return false;
    }
  }

  private checkIsYearsInOrder(currentDiffAddressMonth): boolean {
    if (currentDiffAddressMonth < 120) {
      return false;
    } else {
      return true;
    }
  }
}
