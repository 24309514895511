import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { License } from 'src/app/shared/models/license.model';
import dayjs from 'dayjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Action } from 'src/app/shared/actions/models/action.model';
import { Status } from 'src/app/shared/status-banner/models/status.model';
import { base64ToFile } from 'ngx-image-cropper';
import { LicenseDataService } from 'src/app/new-license-scan/license-data.service';
import { of } from 'rxjs';
import { first, tap, catchError, finalize } from 'rxjs/operators';
import { FileDataService } from 'src/app/shared/file-data/file-data.service';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';

import * as mime from 'mime';

const DRIVER_LICENSE_TYPE_MAP = {
  Front: {
    imageType: 'frontlicenseimage',
    imageUrl: 'frontScanFileUrl',
    imageUploadedDate: 'frontImageUploadedDate'
  },
  Back: {
    imageType: 'backlicenseimage',
    imageUrl: 'backScanFileUrl',
    imageUploadedDate: 'backImageUploadedDate'
  }
};

const FRONT_LICENSE_DEFAULT_NAME = 'front-license';
const BACK_LICENSE_DEFAULT_NAME = 'back-lincense';

@Component({
  selector: 'app-profile-license-image-view',
  templateUrl: './profile-license-image-view.component.html',
  styleUrls: ['./profile-license-image-view.component.scss']
})
export class ProfileLicenseImageViewComponent implements OnInit {
  title: string;
  fileUrl: string;
  safeUrl: SafeUrl;
  previewImage: string;
  generatedDate: string;
  isCaptured: boolean;
  isApiLoading: boolean;
  WIDTH = 420;
  HEIGHT = 380;
  license: any;
  mobile: boolean;
  html: any;
  error: any;
  status: Status;
  actions: Action[];
  showCrop: boolean;
  capturedImageUrl: any;
  capturedImage: any;
  isFront: boolean;

  transform: ImageTransform = {};
  imageChangedEvent: any;
  canvasRotation = 0;

  @ViewChild('previewImageCropper') previewImageCropper: ImageCropperComponent;

  loading;
  documentPicture;
  showUploadedPicture: boolean;
  acceptTypesPicture = ['image/png', 'image/jpg', 'image/jpeg', 'image/tiff'];
  imageFormat: any;

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly registrationService: RegistrationService,
    private sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService,
    private readonly licenseDataService: LicenseDataService,
    private readonly fileDataService: FileDataService
  ) {}

  ngOnInit(): void {
    this.getLicenseDetails();
    if (this.deviceService.isTablet() || this.deviceService.isMobile()) {
      // checks if the device is a mobile device or tablet
      this.mobile = true;
    } else {
      this.mobile = false;
      this.html = this.sanitizer.bypassSecurityTrustHtml(
        `<p>Please switch to a mobile device and login to your profile to proceed with the identity check process.</p><p>Thank you!</p>`
      );
    }
    if (!this.mobile) {
      this.status = this.getSwitchToMobileMessage();
    }
  }

  getLicenseDetails() {
    this.registrationService.getLicense().subscribe((response: License) => {
      this.license = response;
      if (response && response?.[DRIVER_LICENSE_TYPE_MAP[title]?.imageUploadedDate]) {
        this.generatedDate = dayjs(response[DRIVER_LICENSE_TYPE_MAP[title].imageUploadedDate]).format('DD MMM YYYY');
      }
    });
    this.title = this.route.snapshot.queryParams.title;
    const documentId = this.route.snapshot.queryParams.id;
    const title = this.route.snapshot.queryParams?.title;
    const licenseType = DRIVER_LICENSE_TYPE_MAP[title]?.imageType;
    if (!documentId || !title) {
      this.goBack();
    }
    if (title === 'Front') {
      this.isFront = true;
    }
    if (documentId && licenseType) {
      this.registrationService.getLicense(documentId, undefined, licenseType).subscribe((response: any) => {
        const imageUrl = DRIVER_LICENSE_TYPE_MAP[title]?.imageUrl;

        if (response && response[imageUrl]) {
          const url = `${response[imageUrl]}`;
          const parentUrl = url
            ?.split('?')[0]
            ?.split('?')[0]
            ?.split('/');

          if (parentUrl && parentUrl[parentUrl.length - 1]?.split('.')[1] === 'pdf') {
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response[imageUrl] + '#toolbar=0');
          } else {
            this.previewImage = response[imageUrl];
          }
        }
      });
    }
  }

  goBack(): void {
    this.location.back();
  }

  openGallery(galleryImageInput: any): void {
    galleryImageInput.click();
  }

  onProcessPictureFile(event: any) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      if (file) {
        const extension = file.name
          .split('.')
          .pop()
          .toLowerCase();
        const fileType = file.type;
        if (fileType.startsWith('image/')) {
          this.imageFormat = extension ? extension : fileType.split('/')[1];
        }
      }
      reader.onload = () => {
        this.documentPicture = file;
      };
      this.loading = true;
      this.showUploadedPicture = true;
      this.showCrop = true;
      this.isCaptured = true;
      this.imageChangedEvent = event;
    }
  }

  onCancelCrop() {
    this.showCrop = false;
    this.isCaptured = false;
    this.loading = false;
    this.capturedImage = undefined;
    this.capturedImageUrl = undefined;
    this.showUploadedPicture = false;
    this.isApiLoading = false;
  }

  onClose() {
    this.capturedImage = undefined;
    this.capturedImageUrl = undefined;
    this.showCrop = false;
  }

  onRetake() {
    this.showCrop = false;
    this.isCaptured = false;
    this.capturedImage = undefined;
    this.capturedImageUrl = undefined;
  }
  onImageCropped(event: ImageCroppedEvent) {
    if (event && event.base64) {
      let name = BACK_LICENSE_DEFAULT_NAME;
      if (this.isFront) {
        name = FRONT_LICENSE_DEFAULT_NAME;
      }
      const imageFile = new File([base64ToFile(event.base64)], name, { type: this.imageFormat });
      let reader = new FileReader();
      let url;
      reader.readAsDataURL(imageFile);
      reader.onload = event => {
        url = event.target.result;
        this.capturedImage = imageFile;
        this.capturedImageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
      };
    }
  }

  onFinish() {
    this.saveLicense();
    this.isCaptured = false;
    this.showCrop = false;
    this.loading = false;
    this.previewImage = '';
    this.showUploadedPicture = false;
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  private isLicensePresent(): boolean {
    return this.license && this.license?.id ? true : false;
  }

  saveLicense(): void {
    if (this.capturedImage) {
      this.isApiLoading = true;

      let saveModel: any = {};
      if (this.isFront) {
        saveModel.frontScanImageExtension = this.capturedImage.type;
      } else {
        saveModel.backScanImageExtension = this.capturedImage.type;
      }
      const licensePresent = this.isLicensePresent();

      if (licensePresent) {
        this.licenseDataService
          .updateLicense(saveModel, this.license.id)
          .pipe(
            first(),
            tap(
              response => {
                this.uploadLicenseImage(response, this.capturedImage);
              },
              catchError(error => {
                return of(false);
              })
            ),
            finalize(() => {
              this.isApiLoading = false;
            })
          )
          .subscribe();
      }
    }
  }
  onImageLoaded() {
    this.loading = false;
  }

  onCropperReady() {
    this.loading = false;
  }
  onLoadImageFailed() {}
  uploadLicenseImage(response, licenseImage) {
    if (response) {
      let licenseUrl: any;
      let licenseNewName: any;
      if (this.isFront) {
        licenseUrl = response.frontScanImageUrl;
        licenseNewName = response.frontScanImageFile;
      } else {
        licenseUrl = response.backScanImageUrl;
        licenseNewName = response.backScanImageFile;
      }
      const fileExtension = licenseNewName.split('.').pop()?.toLowerCase() || '';
      let contentType = mime.getType(fileExtension);
      if (!contentType) {
        contentType = 'image/png';
      }
      const newFile = new File([licenseImage], licenseNewName, { type: contentType });
      const fileContentType = newFile.type ? newFile.type : 'image/png';
      this.fileDataService
        .uploadFile(newFile, licenseUrl, fileContentType)
        .pipe(
          first(),
          tap(response => {
            this.getLicenseDetails();
            this.capturedImage = undefined;
            this.capturedImageUrl = undefined;
            this.showCrop = false;
          }),
          catchError(error => {
            return of(false);
          }),
          finalize(() => {
            this.isApiLoading = false;
          })
        )
        .subscribe();
    }
  }

  private getSwitchToMobileMessage(): Status {
    return {
      message: 'Switch to a mobile device to proceed',
      svgIcon: 'alert-circle-red',
      color: 'switch_to_mobile_banner'
    } as Status;
  }
}
