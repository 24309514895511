import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetResidenceHistory } from './residence.actions';
import { Directive, Injectable } from '@angular/core';
import { ResidenceAddress } from 'src/app/shared/models/residence-address.model';

export interface RegistrationResidenceAddressStateModel {
  residenceList: ResidenceAddress[];
}
@State<RegistrationResidenceAddressStateModel>({
  name: 'residence',
  defaults: {
    residenceList: []
  }
})
@Directive({})
export class ResidenceState {
  @Selector() static residenceHistory(state: RegistrationResidenceAddressStateModel) {
    return state.residenceList;
  }
  @Action(SetResidenceHistory)
  setResidenceHistory(ctx: StateContext<RegistrationResidenceAddressStateModel>, { payload }: SetResidenceHistory) {
    ctx.patchState({
      residenceList: payload
    });
  }
}
