import { Component, OnInit, ViewChild, NgZone, ViewChildren, AfterViewInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { catchError, first, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Employment } from 'src/app/shared/models/employment.model';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { Location } from '@angular/common';
import dayjs, { Dayjs } from 'dayjs';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ActivatedRoute } from '@angular/router';
import { Driver } from 'src/app/shared/models/driver.model';
import { of, pipe } from 'rxjs';
import { StorageService } from 'src/app/shared/storage/storage.service';

const ACTION_SAVE_EMPLOYMENT_HISTORY = 'Save employment history';
const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const VALID_REFERENCE_PHONE_NUMBER_FORMAT_MESSAGE = 'Must be ###-###-####';
const DASH_NOT_ALLOW_AT_BEGINING = 'Dash and space is not allowed at begining';
const NUMBERS_SPEICAL_CHARACTERS_NOT_ALLOWED = 'Only alphabets, hyphen, apostrophe and space are allowed';
const EMPLOYMENT_STATE_EMPLOYED = 'employed';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
const InvalidEmailAddress = 'Invalid email address';

@Component({
  selector: 'app-employment-history-form-employed',
  templateUrl: './employment-history-form-employed.component.html',
  styleUrls: ['./employment-history-form-employed.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class EmploymentHistoryFormEmployedComponent implements OnInit, AfterViewInit {
  employmentList = [];
  isFirstRecord: boolean;
  form: FormGroup;
  errorMessage: string;
  lastDateOfMonth = dayjs()
    .endOf('month')
    .valueOf();
  currentDate = new Date(this.lastDateOfMonth);

  lastDate = dayjs(this.currentDate).subtract(10, 'year');
  isGreaterThan10years: boolean = false;
  editFormTitle: string = 'Employment';
  minFromDate;
  maxToDate = dayjs(this.currentDate);
  isContentReady: boolean = false;
  employmentObj: any;
  isModifyNewCurrentAddress: boolean;
  driver: Driver;
  currentNewEmployment;

  @ViewChild('empAddressText', { static: false }) empAddressText: any;

  @ViewChildren('toDatePicker') toDateList;
  @ViewChildren('fromDatePicker') fromDateList;
  private subs = new SubSink();
  licenseClassNonON = ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5'];
  licenseClassON = ['Class A', 'Class B', 'Class C', 'Class D', 'Class E', 'Class F '];
  radiusCanada = [
    { value: 'provincial', label: 'Provincial' },
    { value: 'acrossCanada', label: 'Canada wide' },
    { value: 'canadaAndUS', label: 'Canada & US' }
  ];
  radiusUs = [
    { value: 'intrastate', label: 'Intrastate' },
    { value: 'acrossUS', label: 'Across US' },
    { value: 'USAndCanada', label: 'US & Canada' }
  ];
  provinceLicenseClass = [];
  assignedRadiusValues = [];
  isOntario = false;
  positions = [
    { value: 'companyDriver', displayName: 'Company Driver' },
    { value: 'ownerOperator', displayName: 'Owner Operator' },
    { value: 'other', displayName: 'Other' }
  ];
  equipmentTypes: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly registrationService: RegistrationService,
    private readonly location: Location,
    private readonly driverApiService: DriverApiService,
    private readonly timeService: DateTimeService,
    private readonly fb: FormBuilder,
    private readonly toastService: ToastService,
    private readonly zone: NgZone,
    private readonly storageService: StorageService,
    @Inject('environmentData') public environment: any
  ) {
    this.errorMessage = undefined;
    this.form = this.createForm();
  }

  ngOnInit(): void {
    this.driver = this.registrationService.getDriver();
    this.currentNewEmployment = this.storageService.find('currentEmploymentState');
    this.loadExperience();
    this.onPositionChanged();
  }

  ngAfterViewInit(): void {
    this.loadGoogleAutoComplete();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onPositionChanged() {
    this.position.valueChanges.subscribe(value => {
      this.updateValidators();
    });
  }

  updateValidators() {
    const positionValue = this.form.get('position')?.value; // Get the current position value
    const otherPositionControl = this.form.get('otherPosition');
    const licenseTypeControl = this.form.get('licenseType');
    const assignedRadiusControl = this.form.get('assignedRadius');

    if (positionValue === 'other') {
      otherPositionControl.setValidators([Validators.required, Validators.pattern("^[A-Za-z-'\\s]+$")]);
    } else {
      otherPositionControl.clearValidators();
      otherPositionControl.setValue('');
    }
    otherPositionControl.updateValueAndValidity();

    if (positionValue === 'companyDriver' || positionValue === 'ownerOperator') {
      this.form.setValidators(this.atLeastOneCheckboxCheckedValidator);
      assignedRadiusControl.setValidators([Validators.required]);
      licenseTypeControl.setValidators([Validators.required]);
    } else {
      assignedRadiusControl.clearValidators();
      licenseTypeControl.clearValidators();
      const currentValidators = this.form.validator
        ? [this.form.validator].filter(v => v !== this.atLeastOneCheckboxCheckedValidator)
        : [];
      this.form.setValidators(currentValidators);
    }
    assignedRadiusControl.updateValueAndValidity();
    licenseTypeControl.updateValueAndValidity();
  }

  onAddNewEmployment(isMissingIndex?: number): void {
    this.form = this.createForm();
    this.editFormTitle = 'Employment';
    if (this.isFirstRecord || this.isModifyNewCurrentAddress) {
      this.editFormTitle = `Current ${this.editFormTitle}`;
      this.toDate.setValue(this.currentDate);
    } else {
      this.editFormTitle = `Previous ${this.editFormTitle}`;
      const toDate = dayjs(this.employmentList[this.employmentList.length - 1].fromDate);
      this.form.get('toDate').setValue(toDate);
      this.form.get('isCurrentEmployment').setValue(false);
    }
    this.maxToDate = dayjs(this.currentDate);
    if (isMissingIndex) {
      this.updateDateMaxAndMin(this.form, isMissingIndex);
    }
    if (this.isModifyNewCurrentAddress) {
      // When user adds new current record, they should not be able to choose toDate month of previous record (i.e. first record)
      this.minFromDate = dayjs(
        this.currentNewEmployment?.isCurrentEmployment ? this.employmentList[0].fromDate : this.employmentList[0].toDate
      )
        .add(1, 'month')
        .set('date', 1);
    }
    this.loadGoogleAutoComplete();
  }

  //here element will be used to detect the datepicker control
  chosenMonthHandler(
    normalizedMonth: Dayjs,
    datepicker: MatDatepicker<Dayjs>,
    currentForm: FormGroup,
    element?: string
  ): void {
    const date = dayjs()
      .set('month', normalizedMonth.get('month'))
      .set('year', normalizedMonth.get('year'));

    if (element) {
      const assignDate = dayjs(date).endOf('month');
      currentForm.patchValue({
        toDate: assignDate
      });
    } else {
      const assignDate = dayjs(date).set('date', 1);
      let currentToDate = dayjs(currentForm.get('toDate').value);

      if (
        currentToDate.get('month') === assignDate.get('month') &&
        currentToDate.get('year') === assignDate.get('year')
      ) {
        currentToDate = dayjs(currentToDate).endOf('month');
      } else {
        currentToDate = dayjs(currentToDate).set('date', 1);
      }

      currentForm.patchValue({
        fromDate: assignDate
      });
      currentForm.get('toDate').setValue(currentToDate, { emitEvent: false });
    }

    this.form.updateValueAndValidity();
    datepicker.close();
  }

  onUpdateEmploymentPressed($event): void {
    if ($event) {
      this.maxToDate = dayjs(this.currentDate);
      this.form = this.createForm();
      this.editFormTitle = 'Employment';
      this.populateForm($event);

      if ($event?.isCurrentEmployment) {
        this.editFormTitle = `Current ${this.editFormTitle}`;
      } else {
        this.editFormTitle = `Previous ${this.editFormTitle}`;
      }
      this.employmentObj = this.employmentList.find(e => e.id === $event.id);
      this.loadGoogleAutoComplete();
    }
  }

  onCancel(): void {
    this.location.back();
  }

  onLog() {
    console.log(this.form, 'Form Object');
  }

  goBack(): void {
    this.location.back();
  }

  onSubmit(form: FormGroup): void {
    this.isContentReady = false;
    if (!form.valid) {
      return;
    }
    let model = null;
    model = this.prepareSaveModel();
    const modelWithId = { ...model };

    if (model && model.id) {
      let id = model.id;
      delete model.id;
      if (model.isCurrentEmployment) {
        delete model.toDate;
        delete model.reasonForLeaving;
      }
      this.subs.add(
        this.registrationService.updateEmployment(model, id).subscribe(
          () => {
            this.errorMessage = undefined;
            const updatedEmp = this.employmentList.findIndex(emp => emp.id === modelWithId.id);
            this.employmentList[updatedEmp] = modelWithId;
            this.registrationService.updateEmploymentHistoryStore([...this.employmentList]);
            this.onCancel();
          },
          (error: any) => {
            this.toastService.showError(error?.message);
            this.isContentReady = true;
            this.onCancel();
          }
        )
      );
    } else if (model) {
      if (this.currentNewEmployment?.isCurrentEmployment) {
        this.currentNewEmployment.isCurrentEmployment = false;
        this.currentNewEmployment.toDate = model.fromDate;

        const id = this.currentNewEmployment.id;
        delete this.currentNewEmployment.id;
        delete this.currentNewEmployment.modifiedDate;
        delete this.currentNewEmployment.creationDate;
        delete this.currentNewEmployment.version;
        delete this.currentNewEmployment.reasonForLeaving;

        this.updateExistingAndCreateNewEmployment(this.currentNewEmployment, id, model);
      } else {
        this.onEmploymentAdded(model);
      }
    } else {
      this.onCancel();
    }
    this.form.reset();
  }

  private updateExistingAndCreateNewEmployment(model: Employment, id: string, newCurrentEmployment?: Employment): void {
    this.isContentReady = false;
    this.registrationService
      .updateEmployment(model, id)
      .pipe(
        catchError((error: any) => {
          this.isContentReady = true;
          this.toastService.showError(error?.message);
          return of(false);
        })
      )
      .subscribe(res => {
        if (res) {
          this.errorMessage = undefined;
          if (this.currentNewEmployment) {
            const modelToSave = { ...model, id: id };
            const updatedEmp = this.employmentList.findIndex(emp => emp.id === id);
            this.employmentList[updatedEmp] = modelToSave;
            this.registrationService.updateEmploymentHistoryStore([...this.employmentList]);
            this.storageService.store('currentEmploymentState', null);
            this.onEmploymentAdded(newCurrentEmployment);
          }
        }
      });
  }

  get employerAddress() {
    return this.form.get('employerAddress');
  }
  get employer() {
    return this.form.get('employer');
  }
  get fromDate() {
    return this.form.get('fromDate');
  }
  get toDate() {
    return this.form.get('toDate');
  }
  get position() {
    return this.form.get('position');
  }
  get otherPosition() {
    return this.form.get('otherPosition');
  }
  get licenseType() {
    return this.form.get('licenseType');
  }
  get assignedRadius() {
    return this.form.get('assignedRadius');
  }
  get referenceContactName() {
    return this.form.get('referenceContactName');
  }
  get referenceContactPhoneNumber() {
    return this.form.get('referenceContactPhoneNumber');
  }
  get referenceEmail() {
    return this.form.get('referenceEmail');
  }
  get isCurrentEmployment() {
    return this.form.get('isCurrentEmployment');
  }
  getEmployerAddressErrorMessage() {
    return this.employerAddress.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getEmployerNameErrorMessage() {
    return this.employer.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getFromDateErrorMessage() {
    return this.fromDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getToDateErrorMessage() {
    return this.toDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getPositionErrorMessage() {
    const errors = this.otherPosition.errors;
    if (errors) {
      if (errors.required) {
        return REQUIRED_FIELD_MESSAGE;
      }
      if (errors.pattern) {
        const specialCharactersAtBeginig = new RegExp(/^[-'\s]/);
        if (this.position.value.match(specialCharactersAtBeginig)) {
          return DASH_NOT_ALLOW_AT_BEGINING;
        } else {
          return NUMBERS_SPEICAL_CHARACTERS_NOT_ALLOWED;
        }
      }
    }
    return errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getReferenceContactNameErrorMessage() {
    return this.referenceContactName.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getreferenceContactPhoneNumberErrorMessage() {
    const errors = this.referenceContactPhoneNumber.errors;
    return errors.required ? REQUIRED_FIELD_MESSAGE : errors ? VALID_REFERENCE_PHONE_NUMBER_FORMAT_MESSAGE : '';
  }

  getReferenceEmailErrorMessage() {
    const errors = this.referenceEmail.errors;
    return errors.required ? REQUIRED_FIELD_MESSAGE : errors.pattern ? InvalidEmailAddress : '';
  }

  getRequiredFieldMessage(control: string): string {
    const errors = this.form.get(control)?.errors;
    return errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  onDatePickerClick(datepicker: any) {
    datepicker.open();
  }

  private updateDateMaxAndMin(form: FormGroup, index: number): void {
    form.get('toDate').patchValue(this.employmentList[index - 1].fromDate);
    form.get('fromDate').patchValue(this.employmentList[index + 1].toDate);
    this.minFromDate = dayjs(this.employmentList[index + 1].toDate);
    this.maxToDate = dayjs(this.employmentList[index - 1].fromDate);
  }

  private setLicense(province: string): void {
    this.isOntario = false;
    this.provinceLicenseClass = this.licenseClassNonON;
    if (province === 'ON') {
      this.isOntario = true;
      this.provinceLicenseClass = this.licenseClassON;
    }
  }

  private setAssignedRadius(country: string) {
    if (country === 'Canada') {
      this.assignedRadiusValues = this.radiusCanada;
    } else if (country === 'USA') {
      this.assignedRadiusValues = this.radiusUs;
    } else {
      this.assignedRadiusValues = [];
    }
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      employer: ['', Validators.required],
      fromDate: ['', [Validators.required, this.checkFromDateValidation.bind(this)]],
      toDate: ['', [Validators.required, this.checkToDateValidation.bind(this)]],
      position: ['', Validators.required],
      otherPosition: [''],
      licenseType: [''],
      assignedRadius: [''],
      dumpTruck: [false],
      straightTruck: [false],
      flatbed: [false],
      dryVan: [false],
      reefer: [false],
      tanker: [false],
      other: [false],
      id: [''],
      referenceContactName: ['', Validators.required],
      referenceContactPhoneNumber: ['', [Validators.required, Validators.minLength(12)]],
      referenceEmail: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)((\\.[A-Za-z]{2,}){0,1})\\s*$')
        ]
      ],
      reasonForLeaving: [''],
      employerAddress: ['', Validators.required],
      isCurrentEmployment: ['']
    });

    return form;
  }

  atLeastOneCheckboxCheckedValidator(control: AbstractControl): ValidationErrors | null {
    const dumpTruck = control.get('dumpTruck')?.value;
    const straightTruck = control.get('straightTruck')?.value;
    const flatbed = control.get('flatbed')?.value;
    const dryvan = control.get('dryVan')?.value;
    const reefer = control.get('reefer')?.value;
    const tank = control.get('tanker')?.value;
    const other = control.get('other')?.value;

    // Check if at least one checkbox is true
    const atLeastOneChecked = dumpTruck || straightTruck || flatbed || dryvan || reefer || tank || other;

    return atLeastOneChecked ? null : { atLeastOneCheckboxRequired: true };
  }

  private checkFromDateValidation(control: AbstractControl): Promise<any> {
    if (control && control?.parent) {
      let fromDate = control?.value;
      const toDate = control?.parent?.get('toDate').value || this.currentDate;
      if (fromDate && toDate && dayjs(fromDate) >= dayjs(toDate)) {
        return Promise.resolve({
          maxDateError: true
        });
      }
    }
    return null;
  }

  private checkToDateValidation(control: AbstractControl): Promise<any> {
    if (control && control?.parent) {
      let toDate = control?.value;
      const fromDate = control?.parent?.get('fromDate').value;
      if (fromDate && toDate && dayjs(toDate) <= dayjs(fromDate)) {
        return Promise.resolve({
          minDateError: true
        });
      }
    }
    return null;
  }

  private prepareSaveModel(): Employment {
    if (!this.form.value) {
      return;
    }
    const formModel = this.form.value;
    const equipmentTypes: string[] = [];
    if (formModel.dumpTruck) equipmentTypes.push('dumpTruck');
    if (formModel.straightTruck) equipmentTypes.push('straightTruck');
    if (formModel.flatbed) equipmentTypes.push('flatbed');
    if (formModel.dryVan) equipmentTypes.push('dryVan');
    if (formModel.reefer) equipmentTypes.push('reefer');
    if (formModel.tanker) equipmentTypes.push('tanker');
    if (formModel.other) equipmentTypes.push('other');

    const model: Employment = {
      employer: formModel?.employer as string,
      fromDate: formModel?.fromDate ? (dayjs(formModel.fromDate).format('YYYY-MM-DD') as string) : '',
      toDate: formModel?.toDate ? (dayjs(formModel.toDate).format('YYYY-MM-DD') as string) : '',
      position: formModel?.otherPosition ? (formModel.otherPosition as string) : (formModel.position as string),
      licenseType: formModel?.licenseType ? (formModel.licenseType as string) : '',
      assignedRadius: formModel?.assignedRadius ? (formModel.assignedRadius as string) : '',
      id: formModel?.id ? (formModel.id as string) : '',
      referenceContactName: formModel?.referenceContactName ? (formModel.referenceContactName as string) : '',
      referenceContactPhoneNumber: formModel.referenceContactPhoneNumber
        ? this.getPhoneNumberWithoutDash(formModel.referenceContactPhoneNumber)
        : '', // numbers only phone number
      referenceEmail: formModel?.referenceEmail ? (formModel.referenceEmail as string) : '',
      employmentState: EMPLOYMENT_STATE_EMPLOYED,
      reasonForLeaving: formModel?.reasonForLeaving ? (formModel.reasonForLeaving as string) : '',
      employerAddress: formModel?.employerAddress ? formModel.employerAddress : '',
      isCurrentEmployment: formModel?.isCurrentEmployment ? formModel.isCurrentEmployment : '',
      equipmentTypes: equipmentTypes.length ? equipmentTypes : undefined
    };

    if (this.isFirstRecord || model?.isCurrentEmployment) {
      model.isCurrentEmployment = true;
      delete model.toDate;
    }

    // Removing null values from model
    Object.keys(model).forEach(key => {
      if (model[key] === null || model[key] === '') {
        delete model[key];
      }
    });

    delete model.otherPosition;
    if (model.position !== 'ownerOperator' && model.position !== 'companyDriver') {
      delete model.licenseType;
      delete model.assignedRadius;
      delete model.equipmentTypes;
    }
    return model as Employment;
  }

  private populateForm(employment: Employment): void {
    const provinceMatch = employment.employerAddress.match(/, ([A-Z]{2}) \w+ \d{5}|\d{3}|\d{4},/);
    let provinceValue = provinceMatch ? provinceMatch[1] : null;
    const countryMatch = employment.employerAddress.match(/, ([A-Za-z\s]+)$/);
    const country = countryMatch ? countryMatch[1].trim() : null;

    if (!provinceValue) {
      const fullAddress = employment.employerAddress.split(',');
      if (fullAddress.length > 2) {
        const provinceAndZip = fullAddress[fullAddress.length - 2].trim();
        const getProvince = provinceAndZip.split(' ');
        if (getProvince.length > 0) {
          provinceValue = getProvince[0];
        }
      }
    }
    this.setLicense(provinceValue);
    this.setAssignedRadius(country);
    const phoneNumberWithDashes = employment?.referenceContactPhoneNumber
      ? employment.referenceContactPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      : '';
    if (employment.equipmentTypes && Array.isArray(employment.equipmentTypes)) {
      employment.equipmentTypes.forEach((type: string) => {
        const checkboxControl = this.form.get(type);

        if (checkboxControl) {
          checkboxControl.setValue(true);
        }
      });
    }
    this.form.patchValue({
      employer: employment.employer,
      fromDate: employment.fromDate,
      toDate: employment.toDate || (dayjs(this.currentDate).format('YYYY-MM-DD') as string),
      position:
        employment.position !== 'companyDriver' && employment.position !== 'ownerOperator'
          ? 'other'
          : employment.position,
      otherPosition:
        employment.position !== 'companyDriver' && employment.position !== 'ownerOperator' ? employment.position : '',
      assignedRadius: employment.assignedRadius,
      licenseType: employment.licenseType,
      id: employment.id,
      referenceContactName: employment.referenceContactName ? employment.referenceContactName : '',
      referenceContactPhoneNumber: phoneNumberWithDashes ? phoneNumberWithDashes : '',
      referenceEmail: employment.referenceEmail ? employment.referenceEmail : '',
      reasonForLeaving: employment.reasonForLeaving ? employment.reasonForLeaving : '',
      employerAddress: employment.employerAddress ? employment.employerAddress : '',
      isCurrentEmployment: employment?.isCurrentEmployment
    });
    this.updateValidators();
  }

  private getPhoneNumberWithoutDash(phoneNumber): string {
    return phoneNumber.replace(/-/g, '') as string;
  }

  private onEmploymentAdded(model: Employment) {
    // TODO: Remove this logic when API ignores these fields.
    model.id = undefined;
    if (this.isModifyNewCurrentAddress) {
      model.isCurrentEmployment = true;
      delete model.toDate;
    }
    this.isContentReady = false;
    this.subs.add(
      this.registrationService
        .saveEmployment(model)
        .pipe(
          catchError((error: any) => {
            this.isContentReady = true;
            this.toastService.showError(error?.message);
            return of(false);
          })
        )
        .subscribe(response => {
          if (response?.data) {
            const empRecords = [...this.employmentList];
            empRecords.push(response?.data);
            const recordsToSave = [...empRecords];
            this.registrationService.updateEmploymentHistoryStore(recordsToSave);
          }
          this.onCancel();
        })
    );
  }

  private loadExperience(): void {
    this.isContentReady = false;
    this.registrationService.getEmploymentHistory().subscribe(response => {
      this.employmentList = [...response];
      if (this.employmentList) {
        if (this.employmentList?.length) {
          this.employmentList.sort((a, b) => {
            const fromDate1 = new Date(b.fromDate).getTime();
            const fromDate2 = new Date(a.fromDate).getTime();

            if (fromDate1 !== fromDate2) {
              return fromDate1 - fromDate2;
            } else {
              const toDate1 = b.toDate ? b.toDate : this.currentDate;
              const toDate2 = a.toDate ? a.toDate : this.currentDate;
              return new Date(toDate1).getTime() - new Date(toDate2).getTime();
            }
          });

          this.isFirstRecord = false;
          this.checkMissingGap();
        } else {
          this.isGreaterThan10years = false;
          this.isFirstRecord = true;
        }
        this.isContentReady = true;
        this.activatedRoute.queryParams.subscribe(params => {
          let missingIndex = params.missingIndex ? params.missingIndex : 0;
          if (params.newCurrentAddress) {
            this.isModifyNewCurrentAddress = true;
          }
          this.onAddNewEmployment(parseInt(missingIndex));
        });
        this.activatedRoute.params.subscribe(params => {
          if (params.id) {
            let empEvent = this.employmentList.find(el => el.id === params.id);
            if (empEvent) {
              this.onUpdateEmploymentPressed(empEvent);
            }
          }
        });
      } else {
        this.location.back();
      }
    });
  }

  private checkMissingGap(): void {
    let isYearsInOrder = true;
    let employmentData: any[] = [...this.employmentList];
    const currentEmployment = this.employmentList.filter(e => e.isCurrentEmployment);
    let currentDifferenceMonth = 0;
    const currentEmpToDate = dayjs(this.currentDate);
    const currentEmpFromDate = dayjs(currentEmployment[0]?.fromDate);
    currentDifferenceMonth = Math.round(currentEmpToDate.diff(currentEmpFromDate, 'month', true));

    if (this.employmentList.length > 1) {
      let spliceIndex = 0;
      for (let index = 0; index < employmentData.length - 1; index++) {
        if (employmentData[index + 1]) {
          const date2CompareToDate = dayjs(employmentData[index + 1].toDate);
          const date2CompareFromDate = dayjs(employmentData[index].fromDate);
          if (date2CompareToDate.diff(date2CompareFromDate, 'month') === 0) {
            const currToDate = dayjs(employmentData[index + 1].toDate);
            const currFromDate = dayjs(employmentData[index + 1].fromDate);

            currentDifferenceMonth += Math.round(currToDate.diff(currFromDate, 'month', true));

            if (this.checkEmploymentHasTenYearsInfo(currentDifferenceMonth, isYearsInOrder)) {
              return;
            }
          } else {
            if (!spliceIndex) {
              spliceIndex = index + 1;
            } else {
              spliceIndex += index;
            }
            if (employmentData.length - 1 === index + 1 && employmentData.length - 2 === index) {
              this.employmentList.splice(this.employmentList.length - 1, 0, { fromDate: null } as Employment);
            } else {
              this.employmentList.splice(spliceIndex, 0, { fromDate: null } as Employment);
            }
            if (index === 0) {
              isYearsInOrder = this.checkIsYearsInOrder(currentDifferenceMonth);
            } else {
              isYearsInOrder = this.checkIsYearsInOrder(currentDifferenceMonth);
            }
          }
        }
      }
    } else {
      this.checkEmploymentHasTenYearsInfo(currentDifferenceMonth, isYearsInOrder);
    }
  }

  private checkEmploymentHasTenYearsInfo(month: number, isYearsInOrder: boolean): boolean {
    if (month >= 120 && isYearsInOrder) {
      this.isGreaterThan10years = true;
      return true;
    } else {
      this.isGreaterThan10years = false;
      return false;
    }
  }

  private checkIsYearsInOrder(currentDiffAddressMonth): boolean {
    if (currentDiffAddressMonth < 120) {
      return false;
    } else {
      return true;
    }
  }
  private getPlaceAutocomplete(addressText: any): void {
    if (addressText) {
      const autocomplete = new google.maps.places.Autocomplete(addressText?.nativeElement || addressText, {
        componentRestrictions: {
          country: ['US', 'CA']
        },
        types: []
      });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        if (place) {
          this.zone.run(() => {
            const formattedAddress = place.formatted_address;
            this.employerAddress.setValue(formattedAddress, { emitEvent: false });
            const provinceMatch = formattedAddress.match(/, ([A-Z]{2}) \w+ \d{5}|\d{3}|\d{4},/);
            let province = provinceMatch ? provinceMatch[1] : null;

            const countryMatch = formattedAddress.match(/, ([A-Za-z\s]+)$/);
            const country = countryMatch ? countryMatch[1].trim() : null;

            if (country === 'Canada') {
              this.assignedRadiusValues = this.radiusCanada;
            } else if (country === 'USA') {
              this.assignedRadiusValues = this.radiusUs;
            } else {
              this.assignedRadiusValues = [];
            }
            this.assignedRadius.reset();

            if (!province) {
              const fullAddress = formattedAddress.split(',');
              if (fullAddress.length > 2) {
                const provinceAndZip = fullAddress[fullAddress.length - 2].trim();
                const getProvince = provinceAndZip.split(' ');
                if (getProvince.length > 0) {
                  province = getProvince[0];
                }
              }
            }
            this.setLicense(province);
          });
        }
      });
      return addressText;
    }
  }
  private loadGoogleAutoComplete(): void {
    this.getPlaceAutocomplete(this.empAddressText);
  }
}
