<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground residence-history">
  <div class="header-navigation-back">
    <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Residence History</span>
  </div>
  <app-skeleton-emp-residence-history
    *ngIf="!isContentReady"
    isResidenceHistory="true"
  ></app-skeleton-emp-residence-history>
  <div *ngIf="isContentReady">
    <div [ngClass]="showHideAddButton() ? 'content' : 'content-overflow'" fxLayout="column" fxLayoutGap="15px">
      <div class="description-banner">
        <div class="description" *ngIf="showAddNewCurrentText">
          <span class="light-font-weight"> Please update your current address.</span>
        </div>
        <div class="description" *ngIf="!driver.residenceHistoryRequirementsCompleted && !showAddNewCurrentText">
          <span>Enter the addresses of where you have lived since</span
          ><span class="bold-text"> last 3 years</span>,<span> without any gaps.</span>
        </div>
        <div class="description" *ngIf="driver.residenceHistoryRequirementsCompleted">
          <span>Your addresses of where you have lived for</span><span class="bold-text"> last 3 years</span>
        </div>
      </div>

      <div class="progress-bar-container">
        <mat-progress-bar mode="determinate" [value]="progressBarValue"></mat-progress-bar>
      </div>

      <div fxLayout class="progress-bar-text">
        <div fxFlex="50" class="past-date-text bold-text">{{ lastMonthYear }}</div>
        <div fxFlex="50" class="current-date-text bold-text">{{ currentMonthYear }}</div>
      </div>

      <div
        fxLayout="column"
        class="addresses-container"
        [ngClass]="addresses?.length ? 'show-element' : 'hide-element'"
      >
        <mat-error *ngIf="errorMessage" class="error padding-left-md">
          {{ errorMessage }}
        </mat-error>
        <div class="add-new">
          <p *ngIf="showAddNewCurrentText" (click)="onAddNewCurrentAddressText()">Add Current Address</p>
        </div>

        <div *ngFor="let address of addresses; let index = index" fxLayout="column" fxLayoutGap="10px">
          <div
            fxFlex="100"
            fxLayout="column"
            class="item-address"
            [ngClass]="!address?.livedFromDate ? 'missing-data' : 'item-address'"
          >
            <div fxFlex="100" fxLayout>
              <div fxFlex="50" *ngIf="!address?.isCurrentAddress && address?.livedFromDate" class="title-header">
                {{ address?.livedFromDate | date: 'MMM yyyy' }} - {{ address?.livedToDate | date: 'MMM yyyy' }}
              </div>
              <div fxFlex="50" *ngIf="address?.isCurrentAddress" class="title-header">
                {{ address?.livedFromDate | date: 'MMM yyyy' }} - Current
              </div>
              <div fxFlex="50" *ngIf="!address?.livedFromDate" class="title-header-missing">
                Missing Data
              </div>

              <div fxFlex="50" class="edit-icon-container">
                <mat-icon
                  svgIcon="edit-black"
                  *ngIf="address?.livedFromDate"
                  class="edit-pencil-icon"
                  (click)="editIconClicked(address, index)"
                  data-test="edit-button"
                ></mat-icon>
                <mat-icon
                  *ngIf="address?.isCurrentAddress && address?.livedFromDate"
                  svgIcon="add-plus-icon"
                  class="add-plus-icon"
                  (click)="onAddNewCurrentAddress(true, index)"
                  data-test="add-icon"
                ></mat-icon>
                <mat-icon
                  *ngIf="!address?.livedFromDate"
                  svgIcon="add-plus-icon"
                  class="add-plus-icon"
                  (click)="addPreviousAddress(index)"
                ></mat-icon>
              </div>
            </div>
            <div fxFlex="100" fxLayout>
              <div fxFlex="100" class="address-text">{{ address?.streetAddress }} {{ address?.city }}</div>
            </div>
          </div>

          <div
            fxFlex="100"
            fxLayout
            [ngClass]="
              isCurrentAddressEdit && !isPreviousAddress && index == currentIndex ? 'show-element' : 'hide-element'
            "
          >
            <div [formGroup]="form">
              <ng-container
                *ngTemplateOutlet="
                  currentAddress;
                  context: {
                    form: form,
                    index: index
                  }
                "
              ></ng-container>
            </div>
          </div>

          <div
            fxFlex="100"
            fxLayout
            [ngClass]="isPreviousAddress && index == currentIndex ? 'show-element' : 'hide-element'"
          >
            <div [formGroup]="previousAddressForm">
              <ng-container
                *ngTemplateOutlet="
                  previousAddress;
                  context: {
                    previousAddressForm: previousAddressForm,
                    index: index
                  }
                "
              ></ng-container>
            </div>
          </div>
        </div>
      </div>

      <div
        fxFlex="100"
        fxLayout
        [ngClass]="isCurrentAddressEdit && !isPreviousAddress && currentIndex == -1 ? 'show-element' : 'hide-element'"
      >
        <div [formGroup]="form">
          <ng-container
            *ngTemplateOutlet="
              currentAddress;
              context: {
                form: form,
                index: -1
              }
            "
          ></ng-container>
        </div>
      </div>

      <div fxFlex="100" fxLayout [ngClass]="isPreviousAddress && currentIndex == -2 ? 'show-element' : 'hide-element'">
        <div [formGroup]="previousAddressForm">
          <ng-container
            *ngTemplateOutlet="
              previousAddress;
              context: {
                previousAddressForm: previousAddressForm,
                index: -2
              }
            "
          ></ng-container>
        </div>
      </div>

      <ng-container *ngIf="showHideAddButton()">
        <div fxFlex="10" class="button-container" *ngIf="!isLessThan3years && !isCurrentAddressNotPresent">
          <button
            mat-raised-button
            class="btn-add-previous"
            [disabled]="loading"
            [class.save-disabled]="loading"
            (click)="navigateToPreviousAddressForm()"
            data-test="add-previous-address"
          >
            <span>ADD PREVIOUS ADDRESS</span>
          </button>
        </div>

        <div fxFlex="10" class="button-container" *ngIf="isCurrentAddressNotPresent">
          <button
            mat-raised-button
            class="btn-add-previous"
            [disabled]="loading"
            [class.save-disabled]="loading"
            (click)="onAddNewCurrentAddress(true, -1)"
            data-test="add-current-address"
          >
            <span>ADD CURRENT ADDRESS</span>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #currentAddress let-formGroup="form" let-index="index">
  <div [formGroup]="form">
    <mat-expansion-panel disabled hideToggle="true" [expanded]="isCurrentAddressEdit">
      <mat-expansion-panel-header class="required-header">
        <mat-panel-title>
          {{ editFormTitle }}
        </mat-panel-title>
        <button
          mat-button
          class="btn-save"
          (click)="onCurrentAddressSave()"
          [disabled]="!form.valid || loading"
          data-test="save-button"
        >
          Save
        </button>
        <button
          mat-button
          class="btn-cancel"
          [disabled]="addresses?.length > 0 && !isCurrentAddressValid"
          (click)="onCancelCurrentAddress()"
        >
          Cancel
        </button>
      </mat-expansion-panel-header>
      <div class="address-fields" fxLayout="column">
        <div fxFlex="15">
          <mat-label>ADDRESS</mat-label>
          <mat-form-field class="full-width">
            <input matInput id="addressText{{ index }}" formControlName="streetAddress" data-test="address" />
            <mat-error *ngIf="streetAddress.touched && streetAddress.invalid">
              {{ getStreetAddressErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="15">
          <mat-label>COUNTRY</mat-label>
          <mat-form-field class="full-width">
            <mat-select
              formControlName="country"
              (selectionChange)="onCountryChange('form', 'state')"
              data-test="curr-select-country"
            >
              <mat-form-field class="dropdown-search full-width" appearance="outline">
                <input
                  matInput
                  placeholder="Search..."
                  (keydown)="$event.stopPropagation()"
                  (keyup)="onCertnCountrySearch($event)"
                  data-test="curr-txt-country"
                />
              </mat-form-field>

              <mat-option *ngFor="let country of countries" [value]="country.value" data-test="cur-country-name">
                {{ country.key }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="country.touched && country.invalid">
              {{ getCountryErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="15">
          <mat-label>STATE / PROVINCE </mat-label>
          <mat-form-field class="full-width">
            <mat-select
              formControlName="state"
              *ngIf="country && country.value && ['US', 'CA'].includes(country.value.toUpperCase()); else stateInput"
              data-test="state"
            >
              <mat-option value="">Select province</mat-option>
              <ng-container
                *ngIf="
                  (country && country.value && country.value == 'Canada') || country.value == 'CA';
                  else usaOptions
                "
              >
                <mat-option *ngFor="let province of canadaProvinces" [value]="province.value">{{
                  province.name
                }}</mat-option>
              </ng-container>
              <ng-template #usaOptions>
                <mat-option *ngFor="let province of usaProvinces" [value]="province.value">{{
                  province.name
                }}</mat-option>
              </ng-template>
            </mat-select>
            <mat-error *ngIf="state.touched && state.invalid">
              {{ getStateAddressErrorMessage() }}
            </mat-error>
            <ng-template #stateInput>
              <input matInput formControlName="state" data-test="state" />
            </ng-template>
          </mat-form-field>
        </div>
        <div fxFlex="15">
          <mat-label>CITY</mat-label>
          <mat-form-field class="full-width">
            <input matInput formControlName="city" data-test="city" />
            <mat-error *ngIf="city.touched && city.invalid">
              {{ getCityAddressErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="15">
          <mat-label>ZIPCODE / POSTAL CODE</mat-label>
          <mat-form-field class="full-width">
            <input matInput formControlName="zipCode" data-test="zip-code" />
            <mat-error *ngIf="zipCode.touched && zipCode.invalid">
              {{ getZipcodeErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="15" *ngIf="minFromDate">
          <mat-label>FROM (MONTH , YEAR)</mat-label>
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="I have lived here since*"
              formControlName="fromDate"
              [matDatepicker]="fromCurrentAddressPicker"
              [max]="form.get('toDate').value || maxFromDate"
              [min]="minFromDate"
              required
              (click)="onDatePickerClick(fromCurrentAddressPicker)"
              onkeydown="return false;"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fromCurrentAddressPicker"
              data-test="lived-since-date"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #fromCurrentAddressPicker
              touchUi="true"
              startView="multi-year"
              (monthSelected)="chosenMonthHandler($event, fromCurrentAddressPicker, 'fromCurrentAddressPicker')"
            ></mat-datepicker>
            <mat-error *ngIf="fromDate.touched && fromDate.invalid">
              {{ getFromDateErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="15" *ngIf="maxFromDate">
          <mat-label>TO (MONTH , YEAR)</mat-label>
          <mat-form-field class="full-width">
            <input
              matInput
              formControlName="toDate"
              [max]="maxFromDate"
              [min]="form.get('fromDate').value || minFromDate"
              [matDatepicker]="currentToDatePicker"
              required
              [disabled]="form.get('isCurrentAddress').value || isAddingCurrentAddress"
              (click)="onDatePickerClick(currentToDatePicker)"
              onkeydown="return false;"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="currentToDatePicker"
              data-test="to-form-field"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #currentToDatePicker
              touchUi="true"
              startView="multi-year"
              (monthSelected)="chosenMonthHandler($event, currentToDatePicker, 'currentToDatePicker')"
            ></mat-datepicker>
            <mat-error *ngIf="toDate.touched && toDate.invalid">
              {{ getToDateErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-template #previousAddress let-formGroup="previousAddressForm" let-index="index">
  <div [formGroup]="previousAddressForm">
    <mat-expansion-panel disabled hideToggle="true" expanded="{{ isPreviousAddress }}">
      <mat-expansion-panel-header class="required-header">
        <mat-panel-title>
          Previous Address
        </mat-panel-title>
        <button
          mat-button
          class="btn-save"
          (click)="onAddPreviousAddress()"
          [disabled]="!previousAddressForm.valid || loading"
        >
          Save
        </button>
        <button mat-button class=" btn-cancel" (click)="cancelPreviousAddressForm()">Cancel</button>
      </mat-expansion-panel-header>
      <div class="address-fields" fxLayout="column">
        <div fxLayout="column" *ngIf="!turnOffAddressLookup; else manualAddress">
          <div fxFlex="15" class="turnoff-text" (click)="onTurnOffAddressAutoLookup()">Turn OFF address lookup</div>
          <div fxFlex="15">
            <mat-label>ADDRESS</mat-label>
            <mat-form-field class="full-width">
              <input matInput id="previousAddressText{{ index }}" formControlName="fullAddress" />
              <mat-error *ngIf="fullAddress.touched && fullAddress.invalid">
                {{ getFullAddressErrorMessage() }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- Address Lookup turned Off -->
        <ng-template #manualAddress>
          <div fxLayout="column" *ngIf="turnOffAddressLookup">
            <div fxFlex="15">
              <mat-label>ADDRESS</mat-label>
              <mat-form-field class="full-width">
                <input matInput formControlName="previousAddress" />
                <mat-error *ngIf="previousAddress.touched && previousAddress.invalid">
                  {{ getPreviousAddressErrorMessage() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="15">
              <mat-label>COUNTRY</mat-label>
              <mat-form-field class="full-width">
                <mat-select
                  formControlName="previousAddressCountry"
                  (selectionChange)="onCountryChange('previousAddressForm', 'previousAddressState')"
                  data-test="pre-select-country"
                >
                  <mat-form-field class="dropdown-search full-width" appearance="outline">
                    <input
                      matInput
                      placeholder="Search..."
                      (keydown)="$event.stopPropagation()"
                      (keyup)="onCertnCountrySearch($event)"
                      data-test="pre-txt-country"
                    />
                  </mat-form-field>

                  <mat-option *ngFor="let country of countries" [value]="country.value" data-test="pre-country-name">
                    {{ country.key }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="previousAddressCountry.touched && previousAddressCountry.invalid">
                  {{ getPreviousAddressCountryErrorMessage() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="15">
              <mat-label>STATE / PROVINCE </mat-label>
              <mat-form-field class="full-width">
                <mat-select
                  formControlName="previousAddressState"
                  *ngIf="
                    previousAddressCountry &&
                      previousAddressCountry.value &&
                      ['US', 'CA'].includes(previousAddressCountry.value.toUpperCase());
                    else PreviousAddressStateInput
                  "
                >
                  <mat-option value="">Select province</mat-option>
                  <ng-container
                    *ngIf="
                      (previousAddressCountry &&
                        previousAddressCountry.value &&
                        previousAddressCountry.value == 'Canada') ||
                        previousAddressCountry.value == 'CA';
                      else usaOptions
                    "
                  >
                    <mat-option *ngFor="let province of canadaProvinces" [value]="province.value">{{
                      province.name
                    }}</mat-option>
                  </ng-container>
                  <ng-template #usaOptions>
                    <mat-option *ngFor="let province of usaProvinces" [value]="province.value">{{
                      province.name
                    }}</mat-option>
                  </ng-template>
                </mat-select>
                <mat-error *ngIf="previousAddressCity.touched && previousAddressCity.invalid">
                  {{ getPreviousAddressCityErrorMessage() }}
                </mat-error>
                <ng-template #PreviousAddressStateInput>
                  <input matInput formControlName="previousAddressState" />
                </ng-template>
              </mat-form-field>
            </div>
            <div fxFlex="15">
              <mat-label>CITY</mat-label>
              <mat-form-field class="full-width">
                <input matInput formControlName="previousAddressCity" />
                <mat-error *ngIf="previousAddressCity.touched && previousAddressCity.invalid">
                  {{ getPreviousAddressCityErrorMessage() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="15">
              <mat-label>ZIPCODE / POSTAL CODE</mat-label>
              <mat-form-field class="full-width">
                <input matInput formControlName="previousAddressZipCode" />
                <mat-error *ngIf="previousAddressZipCode.touched && previousAddressZipCode.invalid">
                  {{ getPreviousAddressZipCodeErrorMessage() }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </ng-template>
        <div fxFlex="15" *ngIf="minFromDate">
          <mat-label>FROM (MONTH , YEAR)</mat-label>
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="I have lived here since"
              formControlName="previousAddressFromDate"
              [matDatepicker]="fromPreviousAddressPicker"
              [max]="previousAddressForm.get('previousAddressToDate').value || maxFromDate"
              [min]="minFromDate"
              required
              (click)="onDatePickerClick(fromPreviousAddressPicker)"
              onkeydown="return false;"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fromPreviousAddressPicker"
              data-test="previous-from-date"
            ></mat-datepicker-toggle>
            <mat-datepicker
              touchUi="true"
              startView="multi-year"
              #fromPreviousAddressPicker
              (monthSelected)="chosenMonthHandler($event, fromPreviousAddressPicker, 'previous')"
            >
            </mat-datepicker>
            <mat-error *ngIf="fromDate.touched && fromDate.invalid">
              {{ getPreviousAddressFromDate() }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="15" *ngIf="maxFromDate">
          <mat-label>TO (MONTH , YEAR)</mat-label>
          <mat-form-field class="full-width">
            <input
              matInput
              formControlName="previousAddressToDate"
              [matDatepicker]="previousAddressToDatePicker"
              [max]="maxFromDate"
              [min]="previousAddressForm.get('previousAddressFromDate').value || minFromDate"
              (click)="onDatePickerClick(previousAddressToDatePicker)"
              onkeydown="return false;"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="previousAddressToDatePicker"
              data-test="previous-to-date"
            ></mat-datepicker-toggle>
            <mat-datepicker
              touchUi="true"
              startView="multi-year"
              (monthSelected)="chosenMonthHandler($event, previousAddressToDatePicker, 'toDateControl')"
              #previousAddressToDatePicker
            >
            </mat-datepicker>
            <mat-error *ngIf="fromDate.touched && fromDate.invalid">
              {{ getPreviousAddressToDate() }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</ng-template>
